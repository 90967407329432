import React from "react";
import { connect } from "react-redux";
import { withAuth } from "@okta/okta-react";
import { Redirect } from "react-router-dom";
import { setPageTitleAction } from "../actions/actions";
import {
  DrcMain,
  DrcPanel,
  DrcLoading,
  DrcProgress,
  DrcButton,
  DrcImage
} from "driscolls-react-components";
import { setMasterDataInitialized } from "../actions/MasterActions";

import { setTransactionList } from "../actions/TransactionListActions";
import MasterDataUtilities from "../data/MasterDataUtilities";

const pageTitle = "Initializing Application";

class InitializeApplication extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      message: "",
      successCount: 0,
      errorCount: 0,
      totalCount: 0
    };

    this.statusUpdate = this.statusUpdate.bind(this);
    this.loadMasterData = this.loadMasterData.bind(this);
  }

  statusUpdate(message, successCount, errorCount, totalCount) {
    this.setState({ message, successCount, errorCount, totalCount });
  }

  loadMasterData() {
    this.setState({
      message: "",
      successCount: 0,
      errorCount: 0,
      totalCount: 0
    });

    this.props.auth.getAccessToken().then(token => {
      MasterDataUtilities.Load(
        this.props,
        token,
        this.statusUpdate,
        message => {
          this.props.setMasterDataInitialized(true);
        },
        error => {
          console.error(error);
        }
      );
    });
  }

  componentWillMount() {
    //TODO: Need way to turn off header navigation so we can stay here till we have the master data
    if (this.props.pageTitle !== pageTitle) {
      this.props.setPageTitle(pageTitle);
    }

    this.loadMasterData();
  }

  render() {
    const { redirectUrl, isMasterDataInitialized } = this.props;

    var percentComplete = Math.ceil(
      ((this.state.successCount + this.state.errorCount) /
        this.state.totalCount) *
        100
    );

    return isMasterDataInitialized ? (
      <Redirect to={redirectUrl} />
    ) : (
      <DrcMain transparent>
        <DrcPanel maxWidth="500px">
          {this.state.successCount + this.state.errorCount ===
            this.state.totalCount && this.state.errorCount > 0 ? (
            <DrcImage
              src={require("../images/fail.png")}
              webp={require("../images/fail.webp")}
              style={{
                position: "absolute",
                height: 100,
                marginTop: -10,
                left: "calc(50vw - 87px)"
              }}
              alt="Thor and Callie are sorry they couldn't get the Data you needed"
            />
          ) : null}
          <DrcLoading
            text={<h1>{this.state.message}</h1>}
            hidden={
              this.state.successCount + this.state.errorCount ===
              this.state.totalCount
            }
          />
          <DrcProgress value={percentComplete} />
          {this.state.successCount + this.state.errorCount >=
            this.state.totalCount && this.state.errorCount > 0 ? (
            <React.Fragment>
              <hr />
              <p>
                We were unable to load {this.state.errorCount} out of{" "}
                {this.state.totalCount} important components for the site to
                load properly. You may want to refresh the website or you can
                proceed with using the application knowing that some aspects may
                not be working.
              </p>
              <hr />
              <div className="row">
                <div className="col-xs-6">
                  <DrcButton
                    isPrimary
                    fullWidth
                    style={{ margin: "8px 0" }}
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    Try, Try Again
                  </DrcButton>
                </div>
                <div className="col-xs-6">
                  <DrcButton
                    isSecondary
                    fullWidth
                    style={{ margin: "8px 0" }}
                    onClick={() => {
                      this.props.setMasterDataInitialized(true);
                    }}
                  >
                    Proceed to Website
                  </DrcButton>
                </div>
              </div>
            </React.Fragment>
          ) : null}
        </DrcPanel>
      </DrcMain>
    );
  }
}

function mapStateToProps(state) {
  return {
    showLoadingScreen: state.rootReducer.showLoadingScreen,
    errorDialog: state.rootReducer.errorDialog,
    pageTitle: state.rootReducer.pageTitle,
    redirectUrl: state.masterReducer.redirectUrl,
    isMasterDataInitialized: state.masterReducer.isInitialized
  };
}

const mapDispatchToProps = dispatch => ({
  setPageTitle: title => dispatch(setPageTitleAction(title)),
  setMasterDataInitialized: isInitialized =>
    dispatch(setMasterDataInitialized(isInitialized)),
  setTransactionList: transactionList =>
    dispatch(setTransactionList(transactionList))
});

export default withAuth(
  connect(mapStateToProps, mapDispatchToProps)(InitializeApplication)
);
