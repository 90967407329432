import {
    SET_MASTER_DATA_REDIRECT_URL,
    SET_MASTER_DATA_INITIALIZED
} from '../actions/MasterActions';

const initialState = {
    isInitialized: false,
    redirectUrl: '/Transaction/'
};

const masterReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MASTER_DATA_REDIRECT_URL:
            return Object.assign({}, state, {
                redirectUrl: action.payload
            });
        case SET_MASTER_DATA_INITIALIZED:
            return Object.assign({}, state, {
                isInitialized: action.payload
            });
        default:
            return state;
    }
};

export default masterReducer;
