import { combineReducers } from 'redux';
import {
    SHOW_LOADING_SCREEN,
    HIDE_LOADING_SCREEN,
    SET_ERRORS,
    HIDE_ERROR_DIALOG,
    SET_PAGE_TITLE,
    ADD_INFO,
    CLEAR_INFO
} from '../actions/actions';

import masterReducer from './masterReducer';
import transactionListReducer from './transactionListReducer';

const initialState = {
    showLoadingScreen: false,
    errorDialog: {
        show: false,
        title: 'Unknown Error',
        content: 'Whazzzuuppp'
    },
    pageTitle: ''
};

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case SHOW_LOADING_SCREEN:
            return {
                ...state,
                showLoadingScreen: true
            };
        case HIDE_LOADING_SCREEN:
            return {
                ...state,
                showLoadingScreen: false
            };
        case SET_ERRORS:
            return {
                ...state,
                errorDialog: {
                    show: true,
                    title: action.payload.title,
                    content: action.payload.errors
                }
            };
        case HIDE_ERROR_DIALOG:
            return {
                ...state,
                errorDialog: {
                    show: false,
                    title: '',
                    content: ''
                }
            };
        case SET_PAGE_TITLE:
            return {
                ...state,
                pageTitle: action.payload
            };
        case ADD_INFO:
            var currentInfos = state.info;
            var currentRelatedInfoIndex = currentInfos.findIndex((info) => info.name === action.payload.name);
            var currentRelatedInfo = currentInfos[currentRelatedInfoIndex];

            if (currentRelatedInfo) {
                if (action.payload.severity >= currentRelatedInfo.severity) {
                    currentInfos = currentInfos.splice(currentRelatedInfoIndex, 1);
                    currentInfos.push(action.payload);
                } else {
                    return state;
                }
            } else {
                currentInfos.push(action.payload);
            }

            localStorage.setItem('Info', JSON.stringify(currentInfos));
            return {
                ...state,
                info: currentInfos
            };
        case CLEAR_INFO:
            var currentClearInfos = state.info;

            if ((action.payload || '').length <= 0) {
                localStorage.setItem('Info', JSON.stringify([]));

                return {
                    ...state,
                    info: []
                };
            }

            var currentClearRelatedInfoIndex = currentClearInfos.findIndex((info) => info.name === action.payload);
            currentClearInfos = currentClearInfos.splice(currentClearRelatedInfoIndex, 1);
            localStorage.setItem('Info', JSON.stringify(currentClearInfos));

            return {
                ...state,
                info: currentClearInfos
            };
        default:
            break;
    }

    return state;
}

export default combineReducers({
    rootReducer,
    masterReducer,
    transactionListReducer
});