import React, { Component } from "react";
import { withAuth } from "@okta/okta-react";
import { Redirect } from "react-router-dom";
import { DrcMain } from "driscolls-react-components";

class LogOut extends Component {
  constructor(props) {
    super(props);

    this.state = { shouldRedirectHome: false };

    this.props.auth.logout("/").catch(err => {
      // Silently ignore no such session errors
      if (err.errorCode !== "E0000007") {
        throw err;
      } else {
        this.setState({ shouldRedirectHome: true });
      }
    });
  }

  render() {
    return this.state.shouldRedirectHome ? (
      <Redirect to="/" />
    ) : (
      <DrcMain>
        <h3>Please Wait... Logging you out shortly...</h3>
      </DrcMain>
    );
  }
}

export default withAuth(LogOut);
