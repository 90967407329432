import { Middleware } from 'one-ring';

const TransactionListMappings = [
    Middleware.CreateMapping('Id', 'Id'),
    Middleware.CreateMapping('UpdatedBy', 'UpdatedBy'),
    Middleware.CreateMapping('RefNbr', 'RefNbr'),
    Middleware.CreateMapping('UpdatedDateTime', 'UpdatedDateTime'),
    Middleware.CreateMapping('MethodType', 'MethodType'),
    Middleware.CreateMapping('TransactionType', 'TransactionType'),
    Middleware.CreateMapping('TransControlNbr', 'TransControlNbr'),
    Middleware.CreateMapping('Resubmittable', 'Resubmittable'),
    Middleware.CreateMapping('Resubmitted', 'Resubmitted'),
    Middleware.CreateMapping('ErrorDescription', 'ErrorDescription'),
    Middleware.CreateMapping('APIName', 'APIName'),
    Middleware.CreateMapping('CreatedBy', 'CreatedBy'),
    Middleware.CreateMapping('EndpointURL', 'EndpointURL'),
    Middleware.CreateMapping('CustomerId', 'CustomerId'),
    Middleware.CreateMapping('Json', 'Json'),
    Middleware.CreateMapping('ResultCount', 'ResultCount'),
    Middleware.CreateArrayMapping('Results', 'Results') //array count
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('TransactionList', 'GET')) {
        Middleware.RegisterMapping('TransactionList', 'GET', TransactionListMappings);
        Middleware.RegisterMapping('TransactionList', 'POST', TransactionListMappings);
    }
}

const TransactionList = {
    ConfigureMiddleware
};

export default TransactionList;
