const PAGE_SIZE = 100;

var getQueryString = currentPage => {
  return `?startPage=${currentPage || 0}&pageSize=${PAGE_SIZE}`;
};

const SearchUtilities = {
  GetQueryString: getQueryString,
  PageSize: PAGE_SIZE
};

export default SearchUtilities;
