export const ADD_TRANSACTION_LIST = 'ADD_TRANSACTION_LIST';
export const setTransactionList = (data, clear) => ({
    type: ADD_TRANSACTION_LIST,
    payload: { data, clear }
});

export const SET_TRANSACTION_FILTERS = 'SET_TRANSACTION_FILTERS';
export const setTransactionFiltersAction = (name, option) => ({
    type: SET_TRANSACTION_FILTERS,
    payload: {
        name,
        option
    }
});

export const SET_FILTER_OPTIONS = 'SET_FILTER_OPTIONS';
export const setFilterOptions = (options) => ({
    type: SET_FILTER_OPTIONS,
    payload: { options }
});

export const CLEAR_TRANSACTION_FILTERS = 'CLEAR_TRANSACTION_FILTERS';
export const clearTransactionFiltersAction = () => ({
    type: CLEAR_TRANSACTION_FILTERS,
    payload: {}
});

export const CLEAR_ONE_FILTER = 'CLEAR_ONE_FILTER';
export const clearOneFilter = (filter) => ({
    type: CLEAR_ONE_FILTER,
    payload: { filter }
});