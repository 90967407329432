import TransactionList from "./TransactionList";

import { DuAuthenticationUtilities } from "driscolls-react-utilities";
import { Middleware } from "one-ring";

function ConfigureAllMiddleware() {
  TransactionList.ConfigureMiddleware();

  Middleware.RegisterDefaultPayloadHandler(token => {
    var email = DuAuthenticationUtilities.GetEmail(token);
    return {
      LastUpdatedBy: email || "Bad Token",
      LastUpdatedDateTime: new Date().toISOString()
    };
  });
}

const MiddlewareToConfigure = {
  ConfigureAllMiddleware
};

export default MiddlewareToConfigure;
