import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { withAuth } from "@okta/okta-react";
import { connect } from "react-redux";
import { DrcMain, DrcOktaWidget } from "driscolls-react-components";
import { setPageTitleAction } from "../actions/actions";

const pageTitle = "";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: null
    };

    this.checkAuthentication = this.checkAuthentication.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.onError = this.onError.bind(this);
  }

  async checkAuthentication() {
    const authenticated = await this.props.auth.isAuthenticated();
    if (authenticated !== this.state.authenticated) {
      this.setState({ authenticated });
    }
  }

  async componentDidMount() {
    this.checkAuthentication();

    if (this.props.pageTitle !== pageTitle) {
      this.props.setPageTitle(pageTitle);
    }
  }

  async componentDidUpdate() {
    this.checkAuthentication();
  }

  onSuccess(res) {
    if (res.status === "SUCCESS") {
      return this.props.auth.redirect({
        sessionToken: res.session.token
      });
    } else {
      // The user can be in another authentication state that requires further action.
      // For more information about these states, see:
      //   https://github.com/okta/okta-signin-widget#rendereloptions-success-error
    }
  }

  onError(err) {
    console.log("error logging in", err);
  }

  render() {
    const errorMessage = this.state.error ? (
      <span className="error-message">{this.state.error}</span>
    ) : null;
    return this.state.authenticated ? (
      <Redirect to="/InitializeApplication/" />
    ) : (
      <React.Fragment>
        <DrcMain transparent>
          <DrcOktaWidget
            baseUrl={window.config.OKTA_URL}
            onSuccess={this.onSuccess}
            onError={this.onError}
          />
          <div
            style={{
              maxWidth: "398px",
              margin: "auto",
              border: "1px solid #8886",
              marginTop: "-9px",
              padding: "10px"
            }}
          >
            <p>{errorMessage}</p>
            <div className="row">
              <div className="col-xs-6" style={{ textAlign: "right" }}>
                Version:
              </div>
              <div className="col-xs-6">{process.env.REACT_APP_VERSION}</div>
            </div>
            <div className="row">
              <div className="col-xs-6" style={{ textAlign: "right" }}>
                Controls Version:
              </div>
              <div className="col-xs-6">
                {window.Driscolls_Controls_Version}
              </div>
            </div>
            <div className="row">
              <div className="col-xs-6" style={{ textAlign: "right" }}>
                Environment:
              </div>
              <div className="col-xs-6">{process.env.NODE_ENV || "N/A"}</div>
            </div>
          </div>
        </DrcMain>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = dispatch => ({
  setPageTitle: title => dispatch(setPageTitleAction(title))
});

export default connect(mapStateToProps, mapDispatchToProps)(withAuth(Home));
