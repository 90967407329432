import { ADD_TRANSACTION_LIST } from '../actions/TransactionListActions';
//import { DiDateUtilities } from 'style-guide';
//import SearchUtilities from '../data/SearchUtilities';

const initialState = {
    transactionList: { Results: [] }
};

const transactionListReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_TRANSACTION_LIST:
            var clear = action.payload.clear;
            var transactionList = action.payload.data;
            var APIOptions = transactionList.Results.map(item => {
                return { value: item.APIName, type: 'APIName' };
            });

            var seenAPIs = [];

            APIOptions = APIOptions.filter(item => {
                var isSeen = seenAPIs.includes(item.value);

                if (!isSeen) {
                    seenAPIs.push(item.value);
                    return true;
                }

                return false;
            });

            var TransactionTypeOptions = transactionList.Results.map(item => {
                return { value: item.TransactionType, type: 'TransactionType' };
            });

            var seenTransactionTypes = [];

            TransactionTypeOptions = TransactionTypeOptions.filter(item => {
                var isSeen = seenTransactionTypes.includes(item.value);

                if (!isSeen) {
                    seenTransactionTypes.push(item.value);
                    return true;
                }

                return false;
            });

            var CustomerIdOptions = transactionList.Results.map(item => {
                return { value: item.CustomerId, type: 'CustomerId' };
            });

            var seenCustomerIds = [];

            CustomerIdOptions = CustomerIdOptions.filter(item => {
                var isSeen = seenCustomerIds.includes(item.value);

                if (!isSeen) {
                    seenCustomerIds.push(item.value);
                    return true;
                }

                return false;
            });

            var fullTransactionList = !clear
                ? [...state.transactionList.Results, ...transactionList.Results]
                : [...transactionList.Results];

            transactionList.Results = fullTransactionList;

            return Object.assign({}, state, {
                transactionList,
                APIOptions,
                TransactionTypeOptions,
                CustomerIdOptions
            });

        default:
            return state;
    }
};

export default transactionListReducer;
