import React from "react";
import { Redirect } from "react-router-dom";
import { Middleware } from "one-ring";
import LoggingUtilities from "../data/LoggingUtilities";
import APIEndPoints from "../services/api";
import SearchUtilities from "../data/SearchUtilities";

const DEFAULT_CACHE_DURATION_IN_MINUTES = 60;
const timeSpanInfoAlert = 7200000; // 2 hours
const timeSpanWarningAlert = 32400000; // 9 hours

var lastMasterDataLoadTime = null;
var hasLoggedMasterDataWarning = false;
var hasLoggedMasterDataInfo = false;
var redirectLocationReducerFunction = () => {};

// const sortOrder = (data) => {
//     return data.sort((a, b) => {
//         return a.Order - b.Order;
//     });
// };

const masterDataCalls = [
  {
    name: "TransactionList",
    endpoint: APIEndPoints.TRANSACTION_LIST + SearchUtilities.GetQueryString(),
    weight: 1,
    returnFunction: (data, props) => {
      props.setTransactionList(data.Results);
    }
  }
];

var getMasterData = (props, token, statusUpdate, onSuccess, onError) => {
  var apiCalls = [];

  masterDataCalls.forEach(call => {
    var middlewareCall = Middleware.CreateSendCall(
      call.name,
      token,
      call.endpoint
    );

    middlewareCall.cacheLifespanInMinutes = DEFAULT_CACHE_DURATION_IN_MINUTES;

    apiCalls.push(
      Middleware.AttachReturnFunction(middlewareCall, call.weight, data => {
        call.returnFunction(data, props);
      })
    );
  });

  Middleware.SendMultiple("Initialize", apiCalls, statusUpdate)
    .then(message => {
      lastMasterDataLoadTime = Date.now();
      onSuccess(message);
      hasLoggedMasterDataInfo = false;
      hasLoggedMasterDataWarning = false;
    })
    .catch(error => {
      lastMasterDataLoadTime = Date.now();
      onError(error);
    });
};

var checkMasterData = isMasterDataLoaded => {
  if (!isMasterDataLoaded) {
    return false;
  }

  if (
    lastMasterDataLoadTime + timeSpanWarningAlert <= Date.now() &&
    !hasLoggedMasterDataInfo
  ) {
    hasLoggedMasterDataInfo = true;
    LoggingUtilities.Add(
      LoggingUtilities.CreateInfo(
        "MasterDataInit",
        "Master Data",
        "Master Data is outdated and hasn't been updated in a very long time. This can cause issues with missing items that may have been added throughout the day.",
        2,
        "/InitializeApplication/"
      )
    );
  }

  if (
    lastMasterDataLoadTime + timeSpanInfoAlert <= Date.now() &&
    !hasLoggedMasterDataWarning
  ) {
    hasLoggedMasterDataWarning = true;
    LoggingUtilities.Add(
      LoggingUtilities.CreateInfo(
        "MasterDataInit",
        "Master Data",
        "Master Data is a little stale and hasn't been updated in a while. This can cause issues with missing items that may have been added recently. It's a good idea to keep this up to date periodically.",
        3,
        "/InitializeApplication/"
      )
    );
  }

  return true;
};

var redirectToLoadMasterData = () => {
  redirectLocationReducerFunction(window.location.pathname);
  return <Redirect to="/InitializeApplication/" />;
};

var registerMasterDataReducerVariables = (
  setMasterDataRedirectLocation,
  setMasterDataInitialized
) => {
  redirectLocationReducerFunction = setMasterDataRedirectLocation;
};

const MasterDataUtilities = {
  Load: getMasterData,
  Check: checkMasterData,
  Redirect: redirectToLoadMasterData,
  Register: registerMasterDataReducerVariables
};

export default MasterDataUtilities;
