import React, { lazy, Suspense } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Security, ImplicitCallback } from "@okta/okta-react";
import {
  DrcThemeProvider,
  DrcHeader,
  DrcSecureGroupRoute,
  DrcPageNotFound,
  DrcBackgroundImages,
  DrcBackdrop,
  DrcDialog,
  DrcMain,
  DrcPanel,
  DrcButton,
  DrcEnvironmentMarker,
  DrcLoading,
  Helmet
} from "driscolls-react-components";
import { Middleware } from "one-ring";
import { DuAuthenticationUtilities } from "driscolls-react-utilities";

import {
  hideErrorDialogAction,
  openCloseNotFinishedModal,
  addInfo,
  showLoadingScreenAction,
  hideLoadingScreenAction,
  setErrorsAction
} from "./actions/actions";
import {
  setInitializeRedirectUrl,
  setMasterDataInitialized
} from "./actions/MasterActions";

import MasterDataUtilities from "./data/MasterDataUtilities";
import LoggingUtilities from "./data/LoggingUtilities";

import InitializeApplication from "./pages/InitializeApplication";
import Home from "./pages/Home";
import LogOut from "./pages/LogOut";
import StorageIcon from "@material-ui/icons/Storage";
import DirectionsRun from "@material-ui/icons/DirectionsRun";
import EventIcon from "@material-ui/icons/Event";

const Dashboard = lazy(() => import("./pages/Dashboard"));
const Transaction = lazy(() => import("./pages/Transaction/Search"));
const Releases = lazy(() => import("./pages/Releases"));

const allLinks = [
  {
    title: "Data Reprocess Platform",
    url: "/Transaction/",
    icon: <StorageIcon />
  },

  { title: "Releases", url: "/Releases/", icon: <EventIcon /> },
  { title: "LogOut", url: "/LogOut/", icon: <DirectionsRun /> }
];

const adminGroups = window.config.OKTA_ADMIN_GROUPS || [];

const allGroups = adminGroups.concat(
  window.config.OKTA_REGULAR_GROUPS || [],
  window.config.OKTA_READ_ONLY_GROUPS || []
);

const SITE_NAME = "Data Reprocess Platform";
const SITE_ACRONYM = "DRP - ";

class App extends React.Component {
  constructor(props) {
    super(props);
    MasterDataUtilities.Register(
      this.props.setInitializeRedirectUrl,
      this.props.setMasterDataInitialized
    );
    LoggingUtilities.Register(this.props.addInfo);
    Middleware.SetProps(
      this.props.showLoadingScreenAction,
      this.props.hideLoadingScreenAction,
      this.props.setErrorsAction
    );
  }

  render() {
    return (
      <DrcThemeProvider>
        <DrcBackgroundImages />
        <DrcEnvironmentMarker />
        <Helmet>
          <title>
            {this.props.pageTitle.length > 0
              ? SITE_ACRONYM + this.props.pageTitle
              : SITE_NAME}
          </title>
        </Helmet>
        <Router history={Router.browserHistory}>
          <Security
            issuer={window.config.OKTA_ISSUER}
            client_id={window.config.OKTA_CLIENT_ID}
            redirect_uri={window.location.origin + "/implicit/callback"}
            onAuthRequired={DuAuthenticationUtilities.OnAuthRequired}
            auto_renew={true}
            scopes={["openid", "profile", "email", "MulesoftAPIAccess"]}
          >
            <DrcHeader
              title={
                this.props.pageTitle.length > 0
                  ? SITE_ACRONYM + this.props.pageTitle
                  : SITE_NAME
              }
              allLinks={allLinks}
              fullWidth={true}
            />
            <Suspense
              fallback={
                <DrcMain transparent>
                  <DrcPanel>
                    <DrcLoading />
                  </DrcPanel>
                </DrcMain>
              }
            >
              <Switch>
                <Route path="/" exact component={Home} />
                <DrcSecureGroupRoute
                  path="/Dashboard/"
                  exact
                  component={Dashboard}
                  groupsAllowed={allGroups}
                />
                <DrcSecureGroupRoute
                  path="/InitializeApplication/"
                  exact
                  component={InitializeApplication}
                  groupsAllowed={allGroups}
                />
                <Route
                  path="/Transaction/"
                  exact
                  component={Transaction}
                  groupsAllowed={allGroups}
                />
                <DrcSecureGroupRoute
                  path="/Releases/"
                  exact
                  component={Releases}
                  groupsAllowed={allGroups}
                />

                <Route path="/implicit/callback" component={ImplicitCallback} />
                <Route path="/LogOut/" exact component={LogOut} />
                <Route component={DrcPageNotFound} />
              </Switch>
            </Suspense>
          </Security>
          <div style={{ position: "fixed", bottom: "10px", right: "10px" }}>
            v. {process.env.REACT_APP_VERSION}
          </div>
          <DrcBackdrop isLoading show={this.props.showLoadingScreen} />
          <DrcDialog
            isError
            title={this.props.errorDialog.title}
            open={this.props.errorDialog.show}
            buttons={
              <DrcButton isError onClick={this.closeErrorDialog}>
                OK
              </DrcButton>
            }
          >
            {this.props.errorDialog.content}
          </DrcDialog>
        </Router>
      </DrcThemeProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    showLoadingScreen: state.rootReducer.showLoadingScreen,
    errorDialog: state.rootReducer.errorDialog,
    pageTitle: state.rootReducer.pageTitle,
    notFinishedModal: state.rootReducer.notFinishedModal
  };
}

const mapDispatchToProps = dispatch => ({
  hideErrorDialog: () => dispatch(hideErrorDialogAction()),
  openCloseNotFinishedModal: isOpen =>
    dispatch(openCloseNotFinishedModal(isOpen)),
  setMasterDataInitialized: isInitialized =>
    dispatch(setMasterDataInitialized(isInitialized)),
  setInitializeRedirectUrl: redirectUrl =>
    dispatch(setInitializeRedirectUrl(redirectUrl)),
  addInfo: info => dispatch(addInfo(info)),
  showLoadingScreenAction: () => dispatch(showLoadingScreenAction()),
  hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction()),
  setErrorsAction: (title, errors) => dispatch(setErrorsAction(title, errors))
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
